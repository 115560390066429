import apiClient from '../../../axios'

export default class AccountsRequisitesService {
  static async getAccountRequisites(accountId, params = {}) {
    try {
      const response = await apiClient.get(`/admin/accounts/${accountId}/requisites`, { params })
      return response.data
    } catch (error) {
      console.error('Error fetching account requisites:', error)
      throw error
    }
  }

  static async getAccountRequisitesB(accountId, params = {}) {
    try {
      const response = await apiClient.get(`/admin/accounts/${accountId}/requisites_b`, { params })
      return response.data
    } catch (error) {
      console.error('Error fetching account requisites B:', error)
      throw error
    }
  }

  static async changeRequisiteActive(requisiteId, active) {
    try {
      const response = await apiClient.get(`/admin/accounts/requisites/${requisiteId}/active/${active}`)
      return response.data.data
    } catch (error) {
      console.error('Error changing requisite active status:', error)
      throw error
    }
  }

  static async changeRequisiteActiveB(requisiteId, active) {
    try {
      const response = await apiClient.get(`/admin/accounts/requisites/b/${requisiteId}/active/${active}`)
      return response.data.data
    } catch (error) {
      console.error('Error changing requisite B active status:', error)
      throw error
    }
  }

  static async fetchAllPsystemsForRequisitesB() {
    try {
      const response = await apiClient.get('/admin/accounts/requisites/b/psystems')
      return response.data.data
    } catch (error) {
      console.error('Error fetching all Psystems for Requisites B:', error)
      throw error
    }
  }

  static async createRequisiteB(params) {
    try {
      const response = await apiClient.post('/admin/accounts/requisites/b', params)
      return response.data.data
    } catch (error) {
      console.error('Error creating requisite B:', error)
      throw error
    }
  }

  static async updateRequisiteB(params) {
    try {
      const response = await apiClient.patch(`/admin/accounts/requisites/b/${params.id}`, params)
      return response.data.data
    } catch (error) {
      console.error('Error updating requisite B:', error)
      throw error
    }
  }

  static async setRequisiteDefault(requisiteId) {
    try {
      const response = await apiClient.get(`/admin/accounts/requisites/${requisiteId}/default`)
      return response.data.data
    } catch (error) {
      console.error('Error setting requisite as default:', error)
      throw error
    }
  }

  static async createRequisite(data) {
    try {
      const response = await apiClient.post('/admin/accounts/requisites', data)
      return response.data.data
    } catch (error) {
      console.error('Error creating requisite:', error)
      throw error
    }
  }

  static async updateRequisite(requisiteId, data) {
    try {
      const response = await apiClient.patch(`/admin/accounts/requisites/${requisiteId}`, data)
      return response.data.data
    } catch (error) {
      console.error('Error updating requisite:', error)
      throw error
    }
  }

  static async deleteRequisite(requisiteId) {
    try {
      const response = await apiClient.delete(`/admin/accounts/requisites/${requisiteId}`)
      return response
    } catch (error) {
      console.error('Error deleting requisite:', error)
      throw error
    }
  }

  static async deleteRequisiteB(requisiteId) {
    try {
      const response = await apiClient.delete(`/admin/accounts/requisites/b/${requisiteId}`)
      return response
    } catch (error) {
      console.error('Error deleting requisite B:', error)
      throw error
    }
  }

  static async getAgentRequisites(payload) {
    try {
      const response = await apiClient.get('/admin/accounts/requisites', { params: payload })
      return response
    } catch (error) {
      console.error('Error getting agent requisites:', error)
      throw error
    }
  }

  static async exportCSVRequisiteAnalytics(payload) {
    try {
      const { data } = await apiClient.post('/admin/accounts/requisites/analytics/report', payload)
      return data
    } catch (error) {
      console.error('Error downloading CSV file:', error)
      throw error
    }
  }
}
