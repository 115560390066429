<template>
  <div>
    <a-form-model
      ref="addRequisiteForm"
      :model="addRequisiteForm"
      :rules="addRequisiteRules"
    >
      <a-row>
        <a-col :span="12" v-if="accounts" class="pr-1">
          <a-form-model-item ref="account_id" :label="$t('labels.account')" prop="account_id">
            <a-select
              v-model="addRequisiteForm.account_id"
              :placeholder="$t('placeholders.selectAgent')"
              @change="handleAgentChange"
              :disabled="readonly"
            >
              <a-select-option v-for="account in accounts" :key="account.id" >
                {{ account.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12" class="pr-1">
          <a-form-model-item ref="psystem_id" :label="$t('labels.paymentMethod')" prop="psystem_id">
            <a-select v-model="addRequisiteForm.psystem_id"
                      :placeholder="$t('placeholders.selectPaySystem')"
                      @change="handlePsystemChange"
                      :loading="psystemsLoading"
                      :disabled="isCreating ? addRequisiteForm.account_id === undefined : psystemsLoading"
            >
              <a-select-option v-for="psystem in psystems" :key="psystem.id" >
                <span role="img">
                  <img :src="psystem.img_path" width="16" height="16" >
                </span>
                {{ psystem.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8" class="pl-1 pr-1">
          <a-form-model-item ref="currency_id" :label="$t('common.currency')" prop="currency_id">
            <a-select v-model="addRequisiteForm.currency_id"
                      :placeholder="$t('placeholders.selectCurrency')"
                      @change="handleCurrencyChange"
                      :disabled="!addRequisiteForm.psystem_id || !addRequisiteForm.account_id"
            >
              <a-select-option v-for="currency in currencies" :key="currency.id" >
                {{ currency.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8" class="pl-1">
          <a-form-model-item ref="max_amount" :label="$t('labels.paymentLimit')" prop="max_amount">
            <a-input-number
              v-model="addRequisiteForm.max_amount"
              class="w-100"
              :default-value="1000"
              :min="1"
              :max="999999"
              :formatter="value => {
                const selectedCurrency = currency || currencies?.find(c => c.id === addRequisiteForm.currency_id)
                return selectedCurrency?.abbr ? `${value}${selectedCurrency.abbr}` : `${value} `
               }"
              :parser="value => {
                const selectedCurrency = currency || currencies?.find(c => c.id === addRequisiteForm.currency_id);
                return value.replace(selectedCurrency?.abbr || ' ', '');
              }"
              :disabled="!(addRequisiteForm.psystem_id > 0 && addRequisiteForm.currency_id > 0)"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8" class="pr-1 pl-2">
          <a-form-model-item ref="address_type_id" :label="$t('labels.addressType')" prop="address_type_id">
            <a-select v-model="addRequisiteForm.address_type_id"
                      :placeholder="$t('placeholders.selectAddressType')"
                      @change="handleAddressTypeChange"
                      :disabled="!addRequisiteForm.psystem_id || psystemsLoading"
            >
              <a-select-option v-for="type in addressTypes" :key="type.id" >
                {{ type.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="9" class="pl-1 pr-1">
          <a-form-model-item ref="address" :label="$t('common.address')" prop="address">
            <a-input v-model="addRequisiteForm.address" :placeholder="$t('placeholders.requisiteAddress')" />
          </a-form-model-item>
        </a-col>
        <a-col :span="9" class="pl-1">
          <a-form-model-item ref="name" :label="$t('common.name')" prop="name">
            <a-input v-model="addRequisiteForm.name" :placeholder="`${$t('pre.eg')} Ivan Ivanovich I.`" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-model-item ref="comment" :label="$t('common.comment')" prop="comment">
            <a-textarea v-model="addRequisiteForm.comment" :placeholder="$t('placeholders.requisiteAgentComment')" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="5">
          <span class="d-flex">
            {{ $t('buttons.btnActivate') }}
            <a-switch
              class="ml-2"
              v-model="addRequisiteForm.is_active"
              @change="handleChangeActive"
              :disabled="addRequisiteForm.is_blocked"
            />
          </span>
        </a-col>
        <a-col :span="19">
          <span class="d-flex">
            <a-checkbox
              class="mr-2"
              v-model="addRequisiteForm.is_blocked"
              @change="handleChangeBlocked"
            />
            blocked
          </span>
        </a-col>
      </a-row>
    </a-form-model>
    <hr/>
    <a-row class="mt-4">
      <a-col :span="8">
        <a-button @click="closeModal">
          {{ $t('buttons.btnCancel') }}
        </a-button>
      </a-col>
      <a-col :span="16" class="text-right">
        <a-button type="primary" @click="onSubmit" :loading="submitLoading">
          {{ requisiteInfo ? $t('buttons.btnSave') : $t('buttons.btnAdd') }}
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import AccountsRequisitesService from '../../../services/api/accounts/requisites/accounts.requisites.service'
import PaySystemsService from '../../../services/api/pay-systems/pay.systems.service'

export default {
  name: 'addRequisite',
  props: ['requisiteInfo', 'accountObj', 'accounts', 'isCreating', 'readonly'],
  computed: {
    addressTypes() {
      if (this.isCreating) {
        if (this.psystems && this.psystems[0] && this.addRequisiteForm.psystem_id) {
          const ind = this.psystems.findIndex((x) => x.id === this.addRequisiteForm.psystem_id)
          if (ind > -1) { return this.psystems[ind].address_types }
        }
        return []
      }
      if (this.psystems && this.psystems[0] && this.addRequisiteForm.psystem_id) {
        const ind = this.psystems.findIndex((x) => x.id === this.addRequisiteForm.psystem_id)
        if (ind > -1) { return this.psystems[ind].address_types }
      }
      return []
    },
    currencies: {
      get() {
        if (this.isCreating) {
          if (this.psystems) {
            const accountIndex = this.accounts.findIndex(account => account.id === this.addRequisiteForm.account_id)
            const accountCurrencyId = this.accounts[accountIndex].currency_id
            const psystem = this.psystems?.find(item => item.id === this.addRequisiteForm.psystem_id)
            return psystem ? psystem.currencies.filter((cur) => cur.id === accountCurrencyId) || [] : []
          }
          return []
        } else {
          if (this.addRequisiteForm.psystem_id) {
            const selectedPsystem = this.psystems?.find(item => item.id === this.addRequisiteForm.psystem_id)
            if (selectedPsystem) {
              const currencies = this.accountObj.id === 999 ? selectedPsystem.currencies : selectedPsystem.currencies.filter((cur) => cur.id === this.accountObj.currency_id)
              return currencies || []
            }
          }
          return []
        }
      },
    },
  },
  data() {
    return {
      submitLoading: false,
      psystemsLoading: false,
      psystems: undefined,
      currency: this.requisiteInfo ? this.requisiteInfo.currency : undefined,
      selectedPsystem: undefined,
      selectedPsystemId: this.requisiteInfo ? this.requisiteInfo.psystem_id : undefined,
      addRequisiteForm: {
        name: this.requisiteInfo ? this.requisiteInfo.name : undefined,
        address: this.requisiteInfo ? this.requisiteInfo.address : undefined,
        account_id: this.accountObj ? this.accountObj.id : undefined,
        psystem_id: this.requisiteInfo ? this.requisiteInfo.psystem_id : undefined,
        currency_id: this.requisiteInfo ? this.requisiteInfo.currency_id : undefined,
        max_amount: this.requisiteInfo ? this.requisiteInfo.max_amount : 1000,
        is_active: this.requisiteInfo ? this.requisiteInfo.is_active : false,
        is_blocked: this.requisiteInfo ? this.requisiteInfo.is_blocked : false,
        comment: this.requisiteInfo ? this.requisiteInfo.comment : '',
        address_type_id: this.requisiteInfo ? this.requisiteInfo.address_type.id : undefined,
      },
      addRequisiteRules: {
        name: [{ required: true, message: `${this.$t('validations.requisiteNameRequired')}`, trigger: 'change' }],
        address: [{ required: true, message: `${this.$t('validations.requisiteAddressRequired')}`, trigger: 'change' }],
        psystem_id: [{ required: true, message: `${this.$t('validations.psystemRequired')}`, trigger: 'change' }],
        currency_id: [{ required: true, message: `${this.$t('validations.currencyRequired')}`, trigger: 'change' }],
        address_type_id: [{ required: true, message: `${this.$t('validations.addressTypeRequired')}`, trigger: 'change' }],
        ...(this.accounts ? { account_id: [{ required: true, message: `${this.$t('validations.agentRequired')}`, trigger: 'change' }] } : {}),
      },
    }
  },
  mounted () {
    if (!this.isCreating) {
      this.getPsystems()
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitModal(record, edit = false) {
      this.$emit('submitModalFunction', record, edit)
    },
    handleChangeBlocked(e) {
      const isChecked = e.target.checked

      if (isChecked && this.addRequisiteForm.is_active === true) {
        this.addRequisiteForm.is_active = false
      }
    },
    handleChangeActive(boolean) {
      if (boolean) {
        this.addRequisiteForm.is_blocked = false
      }
    },
    onSubmit() {
      this.$refs.addRequisiteForm.validate(valid => {
        if (valid) {
          this.requisiteInfo ? this.editRequisiteSubmit() : this.addRequisiteSubmit()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs.addRequisiteForm.resetFields()
    },
    handlePsystemChange(objId) {
      // const ps = this.psystems.find(obj => obj.id === objId)
      if (this.isCreating) {
        return
      }
      if (typeof this.currencies[0] !== 'undefined') {
        this.addRequisiteForm.currency_id = this.currencies[0].id
        this.currency = this.currencies[0]
      }
    },
    handleCurrencyChange(objId) {
      if (this.isCreating) {
        return
      }
      const cur = this.currencies.find(obj => obj.id === objId)
      this.currency = cur
      console.log(this.currency)
    },
    handleAddressTypeChange(selected) {
      // ---
    },
    async addRequisiteSubmit() {
      this.submitLoading = true
      return await AccountsRequisitesService.createRequisite(this.addRequisiteForm).then((response) => {
        this.$message.success(`Requisites added: ${response.name}`)
        this.submitLoading = false
        this.submitModal(response)
      }).catch(error => {
        console.log(error)
        this.$message.error(`Error while creating new requisites: ${error.message}`)
        this.submitLoading = false
      })
    },
    async editRequisiteSubmit() {
      this.submitLoading = true
      return await AccountsRequisitesService.updateRequisite(this.requisiteInfo.id, this.addRequisiteForm).then((response) => {
        this.$message.success(`Requisites updated for ${response?.name}`)
        this.submitLoading = false
        this.submitModal(response, true)
      }).catch(error => {
        console.log(error)
        this.$message.error(`Error while updating requisites: ${error.message}`)
        this.submitLoading = false
      })
    },
    async getPsystems() {
      this.psystemsLoading = true
      let currencyId = null
      if (this.isCreating) {
        const accountIndex = this.accounts.findIndex(account => account.id === this.addRequisiteForm.account_id)
        currencyId = this.accounts[accountIndex].currency_id
      } else {
        currencyId = this.accountObj.currency_id
      }
      return PaySystemsService.getPsystems(false, currencyId).then(
        response => {
          this.psystems = response.filter(obj => obj.id !== 6)
          this.psystemsLoading = false
        },
        error => {
          this.psystemsLoading = false
          return Promise.reject(error)
        },
      )
    },
    async handleAgentChange() {
      await this.getPsystems()
    },
  },
}
</script>

<style scoped>
</style>
